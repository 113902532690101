import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"

import Layout from "../components/layout"
import Seo from "../components/seo"
import StaticBG from "../assets/images/pages/educators-hero.jpg"

export default function Educators({ data }) {
	
  return (
    <Layout>

      <Seo
        title="Educators"
        description="Awards information for Educators"
      />

      			
			<div id="educators" className="brochure">
				<section id="brochure-hero" style={{ backgroundImage: "url(" + StaticBG + ")" }}>
					<div id="brochure-hero-overlay">
						<div className="intro">
							<h2>Educators</h2>
							<div className="tagline">Learn how the Scholastic Awards can support your students</div>
						</div>
					</div>
				</section>
				<section id="brochure-features">
					<div id="educators-how" className="feature-thumb">
						<div className="image">&nbsp;</div>
						<div className="text">
							<h3>How to Enter</h3>
							<p>Create an account and guide your students through the submission process.</p>
							<Link to="/awards/how-to-enter/" className="button">Get Started</Link>
						</div>
					</div>
					<div id="educators-awards" className="feature-cover">
						<div className="image">&nbsp;</div>
						<div className="text">
							<h3><span>Educator Awards</span></h3>
							<p>Educators of award winners may receive National Medals or cash awards. See which awards come with educator recognition.</p>
							<Link to="/scholarships/educator-awards/" className="button">View Awards</Link>
						</div>
					</div>
					<div className="feature-rule"><hr /></div>
					<div id="educators-guides" className="feature-thumb-alt">
						<div className="image">&nbsp;</div>
						<div className="text">
							<h3>Classroom Resources</h3>
							<p>Find discussion guides paired with popular Awards publications and educational resources to help students prepare to enter.</p>
							<Link to="/classroom-guides/" className="button">View Guides</Link>
						</div>
					</div>
					<div id="educators-residencies" className="feature-cover-alt" >
						<div className="image">&nbsp;</div>
						<div className="text">
							<h3><span>Educator Residencies</span></h3>
							<p>Educators with their own art or writing practice are invited to apply for summer residency and retreat opportunities.</p>
							<Link to="/programs/educator-residencies/" className="button">Learn More &amp; Apply</Link>
						</div>
					</div>
					<div className="feature-rule"><hr /></div>
				</section>
				<section id="brochure-faq">
          <div className="faq-widget">
            <h3>FAQ</h3>
            <ul>
            {data.educatorFaqs.nodes.map((faq, index) => (
                      <li key={index}>
                        <Link to={faq.uri}>
                        <strong>{faq.title}</strong>
                        <span>{parse(faq.excerpt)}</span>
                                
                        </Link>
                      </li>
                      ))}
            </ul>
            <Link to="/frequently-asked-questions/" className="button">
              View More
            </Link>
          </div>
        </section>
			</div>
			


     </Layout>
  )
}

export const query = graphql`
  fragment Thumbnail on File {
    childImageSharp {
      fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid_tracedSVG
      }
    }
  }
  query {
    site {
      siteMetadata {
        author
        description
        title
      }
    }
	educatorFaqs: allWpFaq(filter: {faqTags: {nodes: {elemMatch: {slug: {eq: "educators"}}}}}) {
    nodes {
      id
      title
      content
      excerpt
      uri
    }
  }

  }
`